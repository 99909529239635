<header>
    <nav class="navbar navbar-expand-lg">
        <div class="container px-4 px-sm-2">
            <a class="navbar-brand p-0 m-0" [routerLink]="['']">
                <img src="assets/images/logo.webp" class="img-fluid" id="headerLogo" alt="logo">
            </a>
            <div class="nav-right justify-content-center" iheader="" navbar-nav="" li="" ad="navbar">
                <ul class="navbar-nav position-relative d-flex">
                    <li class="nav-item">
                        <a class="nav-link p-0" [routerLink]="['']" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link p-0" [routerLink]="['/about-us']" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" ariaCurrentWhenActive="page">about</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link p-0" [routerLink]="['/team']" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}" ariaCurrentWhenActive="page">team</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link p-0" [routerLink]="['/shop']" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact: true}">shop</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link p-0" [routerLink]="['/cart']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" >Cart</a>
                    </li> -->
                </ul>
            </div>
            <div class="nav-cart d-flex align-items-center">
                <a href="#" class="cart-btn">
                    <img src="assets/images/search.svg" alt="">
                </a>
                <a class="cart-btn desktop-view" [routerLink]="authToken ? '/my-account' : '/auth'">
                    <img src="assets/images/user.svg" alt="">
                </a>
                <a class="cart-btn mobile-view-my-account-access"
                    [routerLink]="authToken ? '/my-account/account' : '/auth'">
                    <img src="assets/images/user.svg" alt="">
                </a>
                <a [routerLink]="['/cart']" class="cart-btn">
                    <img src="assets/images/cart.svg" alt="">
                </a>
                <button class="navbar-toggler p-0" type="button" data-bs-toggle="nav-right" data-bs-target="#navbar"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="toggle-menu-icon"><span></span><span></span><span></span><span></span></span>
                </button>
            </div>
        </div>
    </nav>
</header>