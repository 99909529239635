import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
/**
 * Service to show tostr message to the user
 */
@Injectable({
  providedIn: 'root',
})
export class SessionsStorageService {
  constructor() {}

  setJson<T>(key: string, value: T) {
    localStorage.setItem(key, this.encrypt(JSON.stringify(value)));
  }

  getJson<T>(key: string): T {
    const valueKey = localStorage.getItem(key);

    if (valueKey) {
      return JSON.parse(this.decrypt(valueKey)) as T;
    } else return null as T;
  }

  set(key: string, value: string) {
    localStorage.setItem(key, this.encrypt(value));
  }

  get(key: string): string {
    const valueKey = localStorage.getItem(key);

    if (valueKey) {
      return this.decrypt(valueKey);
    } else return '';
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  clearAll() {
    localStorage.clear();
  }

  encrypt(value: string) {
    return btoa(value);
  }

  decrypt(ectext: string) {
    return atob(ectext);
  }
  setCartUID(key: string) {
    // const value = this.myuuid.get();
    // localStorage.setItem(key, value);
    // return value;
  }
  getCartUID(key: string): string {
    const valueKey = localStorage.getItem(key);

    if (valueKey) {
      return valueKey;
    } else return '';
  }
}
