import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { BehaviorSubject, Observable } from 'rxjs';
import { IUserProfile } from '../../modules/auth/components/model/profile.model';
import { SessionsStorageService } from './storage.service';
import { ILoginResponse } from '../../modules/auth/components/model/login.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private storageService: SessionsStorageService) {
    this.userProfile$ = this.userProfileSubject.asObservable();
  }

  private userProfileSubject: BehaviorSubject<IUserProfile> =
    new BehaviorSubject<IUserProfile>(this.getUserDetails());

  userProfile$: Observable<IUserProfile>;

  saveUserEmailIdTOLocalStorage(userEmail: string) {
    this.storageService.set('userEmailId', userEmail);
  }
  saveUserDetailsTOLocalStorage(user: ILoginResponse) {
    this.saveUserEmailIdTOLocalStorage(user.emailId);
    this.saveUserMobileTOLocalStorage(user.mobileNumber);
    this.saveUserFullNameTOLocalStorage(user.fullName);
  }
  saveUserMobileTOLocalStorage(userMobile: string) {
    this.storageService.set('userMobile', userMobile);
  }
  saveUserFullNameTOLocalStorage(userFullName: string) {
    this.storageService.set('userFullName', userFullName);
  }
  getUserMobileTOLocalStorage() {
    return this.storageService.get('userMobile');
  }

  getUserEmailIdTOLocalStorage() {
    return this.storageService.get('userEmailId');
  }
  getUserFullNameTOLocalStorage() {
    return this.storageService.get('userFullName');
  }
  isLoggedin() {
    return this.getToken() ? true : false;
  }

  storeToken(token: string) {
    this.storageService.set(
      `${environment.appVersion}-${environment.USERDATA_KEY}`,
      token
    );
  }

  storeUserDetails(userDetails: IUserProfile) {
    this.storageService.setJson('appUser', userDetails);
    this.userProfileSubject.next(userDetails);
  }

  getUserDetails() {
    const userDetails = this.storageService.getJson<IUserProfile>('appUser');
    return userDetails;
  }

  canAccess(permission: string) {
    const user: IUserProfile = this.getUserDetails();
    const roles: any = user?.access;
    return roles.includes(permission);
  }

  getToken() {
    return this.storageService.get(
      `${environment.appVersion}-${environment.USERDATA_KEY}`
    );
  }

  logout() {
    this.storageService.clearAll();
  }
}
