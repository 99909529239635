import { Injectable } from "@angular/core";

/**
 * Service to show tostr message to the user
 */
@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    constructor() {

    }

    silly(..._args: any[]) {

    }

    log(..._args: any[]) {

    }

    info(..._args: any[]) {

    }

    error(..._args: any[]) {

    }

}