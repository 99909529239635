import { Component, OnInit } from '@angular/core';
import { HeaderComponent } from '../components/header/header.component';
import { FooterComponent } from '../components/footer/footer.component';

@Component({
  selector: 'app-full-page-layout',
  standalone: true,
  imports: [HeaderComponent, FooterComponent],
  templateUrl: './full-page-layout.component.html',
  styleUrl: './full-page-layout.component.scss',
})
export class FullPageLayoutComponent implements OnInit {
  ngOnInit(): void {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }
}
