import { RouterModule } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../core/services/authentication.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  authToken!:string;
  constructor(private authenticationService: AuthenticationService){}
  ngOnInit(): void {
    this.getUserToken();
  }

  getUserToken(){
    this.authToken = this.authenticationService.getToken();
  }
}
