export const AppMessages = {
  ShippingAddressAddSuccess:"Shipping sddress added successfully",
  ShippingAddressAddError:"Error adding shipping address",
  ForgotEmailError:"Error forgot email",
  ForgotEmailSuccess:"Email sent",
  RegistrationError: 'Error Registering User',
  RegisterEmailSend: `Thank you! We've sent a verification link to the email address`,
  RegistrationSuccess: 'You have been registered successfully',
  LoginError: 'Error Login',
  LoginSuccess:'Login success',
  VerifyingEmailError: 'Error verifying Email',
  VerifyingEmailSuccess: 'Email verification success',
  ResetVerifyingEmailError: 'Error Reset Email',
  resendVerificationError: 'Error resending verification e-mail',
  resendVerificationSuccess: 'resending verification e-mail success',
  ProfileUpdateSuccess: 'Profile updated successfully',
  ProfileUpdateError: 'Error Updating user profile',
  StartupDetailsError: 'Error loading startup details',
  ExpressInterestSuccess: 'Thank you! Your submission has been Sent',
  ExpressInterestError: 'Error registering your interest',
  ResetTokenVerificationError: 'Error validating the link',
  ResetPasswordSuccess: 'Your password was updated successfully',
  ResetPasswordError: 'Reset Password error',
  DeleteStartupError: 'Error deleting startup',

  UserActiveInActiveConfirmationMessage: (
    name: string,
    currentStatusActive: boolean
  ) =>
    `Are you sure you want to mark ${name} as ${
      currentStatusActive ? 'inactive' : 'active'
    }?`,
};
